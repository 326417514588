// STYLE OVERRIDES

//////////////////////
// Colours

$body-bg: #ffffff;
$body-color:#313539;
$primary: #08a1ff;
$secondary: #099e0e;
$success: #62A87C;
$warning: #FFEF00;
$danger: #D10000;
$dark: #313539;
$muted: #7D869C;
$light: #f7f7ff;
$white: #ffffff;
$transparent: transparent;
$secondary-50: rgba($secondary, .5); // used for hover effect on nav items

