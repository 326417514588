.navbar {
    background-color: $primary;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}
.navbar-dark .navbar-nav .nav-link.active {
    color: #000;
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: #000;
}