.rounded-top-large {
    border-radius: 2rem 2rem 0 0;
}
.rounded-all-large {
    border-radius: 2rem;
}

a.card-link {
    text-decoration: none;
    color: black;
}

a.card-link:hover {
    text-decoration: underline;
    color: black;
}
