h1 {
    font-size: 1.5rem;
}

.admin-col {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
}

.admin-col-sm {
    max-width:6rem;
}

.admin-col-md {
    max-width:10rem;
}

.admin-col-lg {
    max-width:20rem;
}